<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>
<v-container fluid v-if='enable'>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Filtres
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-md-2"><v-select
                  :items="stores"
                  item-value=id
                  item-text=name
                  placeholder="Magasin"
                  v-model=store_id
                ></v-select></div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=filter
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-check-bold</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "reset_modal_button"
            v-on:click=reset
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="red"
            text
            class = "export_modal_button"
            v-on:click=exportPDF 
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
<v-card
  elevation="2" style="margin-top:20px"
>
<div class="row">
  <div class='col-md-12' style="padding-top:0px">
    <div class='table-responsive' style="max-height:500px;overflow-y:scroll">
        <table class="table table-striped table-hover tableFixHead" id="content" style="border-top:none">
          <thead>
            <tr>
              <th style="border-right:1px solid #c0c0c0;text-align:left">AGENT</th>
              <th colspan="2" style="border-right:1px solid #c0c0c0">VENTES</th>
              <th colspan="2">COMMISSION</th>
            </tr>
            <tr>
              <th style="border-right:1px solid #c0c0c0;text-align:left">NOM</th>
              <th style="border-right:1px solid #c0c0c0">HTG</th>
              <th style="border-right:1px solid #c0c0c0">USD</th>
              <th style="border-right:1px solid #c0c0c0">CONVERSION <span v-if="filter_rate_id == 1">(HTG)</span> <span v-if="filter_rate_id == 2">(USD)</span><span v-if="filter_rate_id == 3">(HTG)</span></th>
              <th>COMMISSION <span v-if="filter_rate_id == 1">(HTG)</span> <span v-if="filter_rate_id == 2">(USD)</span><span v-if="filter_rate_id == 3">(HTG)</span></th>
            </tr>
            <tr style="color:black;background:#dee2e6">
              <th style='text-align:left;border-right:1px solid #c0c0c0'>TOTAL ({{ getCount() }} AGENTS)</th>

              <th style="border-right:1px solid #c0c0c0">{{ getTotal(1) | formatNumber }}</th>
              <th style="border-right:1px solid #c0c0c0">{{ getTotal(2) | formatNumber }}</th>

              <th style="border-right:1px solid #c0c0c0">{{ getConversion() | formatNumber }}</th>

              <th style="border-right:1px solid #c0c0c0">{{ getCommission() | formatNumber }}</th>

            </tr>
          </thead>
          <tbody style="margin-top:40px">
            
            <tr  v-for="agent in rows" :key="agent.id">
              <td style="border-right:1px solid #c0c0c0" class="text-left">{{ agent.name }} - <strong>{{ agent.commission }}%</strong></td>
              <td style="border-right:1px solid #c0c0c0"><span>{{ agent.sales_htg | formatNumber }}</span></td>

              <td style="border-right:1px solid #c0c0c0" ><span>{{ agent.sales_usd | formatNumber }}</span></td>
              <td style="border-right:1px solid #c0c0c0"><span>{{ agent.conversion | formatNumber }}</span></td>

              <td style="border-right:1px solid #c0c0c0"><span>{{ agent.calculated_commission | formatNumber }}</span></td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</div>
</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import moment from 'moment'
import numeral from 'numeral' 
import { jsPDF } from "jspdf"
import "jspdf-autotable"

export default {
  name: 'AgentsReportView',
  methods: {
    load: function(){
      var self = this;
      var sales_htg = 0
      var sales_usd = 0
      var conversion_htg = 0
      var conversion_usd = 0
      var conversion = 0
      var commission = 0
      this.$axios.get('/reports/cashier?from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          Object.keys(response.data.result).forEach(function(key) {
            let agent = response.data.result[key]
            sales_htg = 0
            conversion_htg = 0
            if(agent.turnover.HTG){
              sales_htg = parseFloat(agent.turnover.HTG.amount)
              conversion_htg = parseFloat(agent.turnover.HTG.amount_converted)
            }else{
              sales_htg = 0
              conversion_htg = 0
            }

            sales_usd = 0
            conversion_usd = 0
            if(agent.turnover.USD){
              sales_usd = parseFloat(agent.turnover.USD.amount)
              conversion_usd = parseFloat(agent.turnover.USD.amount_converted)
            }else{
              sales_usd = 0
              conversion_usd = 0
            }

            if(self.filter_rate_id  == 1){
              commission = (sales_htg + conversion_usd)*parseFloat(agent.commission)/100 
              conversion = (sales_htg + conversion_usd)
            }else{
              commission = (sales_usd + conversion_htg)*parseFloat(agent.commission)/100 
              conversion = (sales_usd + conversion_htg)
            }
            self.rows.push({name: agent.username, commission:agent.commission, sales_htg: sales_htg, sales_usd : sales_usd, conversion:conversion, calculated_commission: commission})
            console.log(self.rows)
          });
          self.enable = true          
        }).catch(function(error){
          console.log(error)
        })
    },
    getCount: function(){
      return Object.keys(this.rows).length
    },
    exportPDF: function(){
      const doc = new jsPDF('l', 'pt')
      doc.autoTable({ html: '#content', theme: 'grid' })
      doc.save("rapport_agents.pdf");
    },
    getConversion: function(){
      var total = 0
      var self = this;
      Object.keys(this.rows).forEach(function(key) {
        total = total + parseFloat(self.rows[key].conversion)
      });
      return numeral(total).format('0,0.00');
    },
    getCommission: function(){
      var total = 0
      var self = this;
      Object.keys(this.rows).forEach(function(key) {
        total = total + parseFloat(self.rows[key].calculated_commission)
      });
      return numeral(total).format('0,0.00')
    },
    getTotal: function(rate){
      var total = 0
      var self = this;
      if(rate == 1){
        Object.keys(this.rows).forEach(function(key) {
          total = total + parseFloat(self.rows[key].sales_htg)
        });
      }else{
        Object.keys(this.rows).forEach(function(key) {
          total = total + parseFloat(self.rows[key].sales_usd)
        });
      }
      return total
    },
    reset: function(){
      this.store_id = ''
      this.filter()
    },
    filter: function(){
      var from = localStorage.debut_periode
      this.rows = []
      var to = localStorage.fin_periode
      var sales_htg = 0
      var sales_usd = 0
      var conversion_htg = 0
      var conversion_usd = 0
      var conversion = 0
      var commission = 0
      var params = '&from='+from.toLocaleString()+'&to='+to.toLocaleString()
      if(this.store_id){
        params = params + '&store='+this.store_id
      }
      var self = this;
      this.enable = false
        this.$axios.get('/reports/cashier?'+params)
        .then(function (response) {
          Object.keys(response.data.result).forEach(function(key) {
            let agent = response.data.result[key]
            sales_htg = 0
            conversion_htg = 0
            if(agent.turnover.HTG){
              sales_htg = parseFloat(agent.turnover.HTG.amount)
              conversion_htg = parseFloat(agent.turnover.HTG.amount_converted)
            }else{
              sales_htg = 0
              conversion_htg = 0
            }

            sales_usd = 0
            conversion_usd = 0
            if(agent.turnover.USD){
              sales_usd = parseFloat(agent.turnover.USD.amount)
              conversion_usd = parseFloat(agent.turnover.USD.amount_converted)
            }else{
              sales_usd = 0
              conversion_usd = 0
            }

            if(self.filter_rate_id  == 1){
              commission = (sales_htg + conversion_usd)*parseFloat(agent.commission)/100 
              conversion = (sales_htg + conversion_usd)
            }else{
              commission = (sales_usd + conversion_htg)*parseFloat(agent.commission)/100 
              conversion = (sales_usd + conversion_htg)
            }
            self.rows.push({name: agent.username, commission:agent.commission, sales_htg: sales_htg, sales_usd : sales_usd, conversion:conversion, calculated_commission: commission})
            // console.log(self.rows)
          });
          // self.rows = response.data.result
          self.enable = true
        }).catch(function(error){
          console.log(error)
        })
    },
    getStores: async function(){
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      await this.$axios.get('/stores?business_id='+this.business_id)
        .then(function (response) {
          self.stores = response.data
        })
    },
  },
  created(){
    this.filter_rate_id = localStorage.filter_rate_id
    this.load()
    this.getStores()
  },
  data: () => ({
      items: [
        {
          text: 'RAPPORT VENTES PAR AGENT',
          disabled: false,
          href: '/',
        }
      ],
      rows: [],
      enable: false,
      loading: false,
      filter_rate_id: 1,
      fluid: true,
      store_id:'',
      business_id : 0,
      stores: [],
      from: moment(new Date()).format('YYYY-MM-DD'),
      to: moment(new Date()).format('YYYY-MM-DD')
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  td{
    font-size: 12px!important;
    vertical-align: middle!important;
  }
  td span, th{
    vertical-align: middle!important;
  }
  th{
    font-size: 12px!important;
  }
  td,th{
    text-align: center;
  }
  .v-select__slot{
    border: 1px solid #ced4da;
    padding: 4px 12px;
    border-radius: 4px;
  }
  .v-text-field > .v-input__control > .v-input__slot:before{
    display: none;
  }
  .v-application .v-btn{
    width: 100%!important;
  }
  .v-text-field{
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
  .v-breadcrumbs__item{
    color: black!important;
  }
  .v-text-field__details{
    display: none!important;
  }
  .v-input__slot{
    margin-bottom: 0px!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
    text-align: center; 
    height:41px!important;
    margin-top:0px;
  }

  .v-btn.reset_modal_button{
    background-color:#1867c0 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .v-btn.export_modal_button{
    background: #F44336 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .form-control {
    height: auto!important; 
    padding: 0.45rem 0.75rem!important;
}
</style>